// import {
//   LiveTranscription,
//   LiveTranscriptionEvents
// } from 'shared/controllers/liveTranscription'
import get from 'lodash/get'
// import forEach from 'lodash/forEach'
import {
  createClient,
  LiveClient,
  LiveTranscriptionEvents,
  LiveConnectionState,
  LiveTranscriptionEvent
  // UtteranceEndEvent
} from '@deepgram/sdk'
import jsLogger from 'js-logger'

const MILLISECOND = 1000
const PREFERRED_SAMPLE_RATE = 16000
const PACKET_DURATION = 500
const BUFFER_SIZE = 2048
const STEREO_FLOAT_TO_MONO_INT16 = 0x10000 / 4 - 1

class RAudio {
  private _stream: MediaStream
  private _blobs: Blob[] = []
  private _dg?: LiveClient
  private _speach: string = ''
  private _onSpeach?: (s: string) => void
  private _onFinalDetected?: (transcript: string) => void
  private _interactionId: string
  private _source?: MediaStreamAudioSourceNode
  private _recorderNode?: AudioWorkletNode
  private _audioContext?: AudioContext
  private _dgKey?: string
  private _dgConnected: boolean = false
  private _keepaliveTimeer: number = 0
  private _isSpeaking: boolean = false
  private _onStartTalking: () => void

  constructor (
    stream: MediaStream,
    onSpeach: (s: string) => void,
    onFinalDetected: (transcript: string) => void,
    interactionId: string,
    dgKey: string,
    onStartTalking: () => void
  ) {
    this._stream = stream
    this._onSpeach = onSpeach
    this._onFinalDetected = onFinalDetected
    this._interactionId = interactionId
    this._dgKey = dgKey
    this._onStartTalking = onStartTalking
    this.start()
    // this._transcriberConnect(dgKey)
  }

  get speach () {
    return this._speach
  }

  private _resetSpeech = () => {
    jsLogger.log('reset speech')
    this._speach = ''
  }

  private _sendKeepalive = () => {
    if (this._dg) {
      const readyState = this._dg.getReadyState()
      if (readyState === LiveConnectionState.OPEN) {
        // jsLogger.log('DG: send keepalive')
        this._dg.keepAlive()
      }
    }
  }

  private _transcriberConnect = (sampleRate: number) => {
    const _deepgram = createClient(this._dgKey)
    jsLogger.log('Deepgram client created')
    const dgParams = {
      model: 'nova-2',
      smart_format: true,
      endpointing: 300,
      // no_delay: true,
      interim_results: true,
      utterance_end_ms: 1200,
      diarize: true,
      punctuate: true,
      sample_rate: sampleRate,
      channels: 1,
      encoding: 'linear16',
      language: 'en-US',
      // utterances: true,
      numerals: true,
      vad_events: true
    }
    try {
      this._dg = _deepgram.listen.live(dgParams)
      jsLogger.debug('Deepgram listen.live', { dgParams })

      this._dg.on(LiveTranscriptionEvents.Error, e => {
        jsLogger.error('DG ERROR:', e)
        setTimeout(() => this._transcriberConnect(sampleRate), 3000)
      })

      this._dg.on(LiveTranscriptionEvents.Open, async () => {
        jsLogger.debug('Deepgram connected')
        this._dgConnected = true
        jsLogger.log('client: connected to websocket')

        this._keepaliveTimeer = window.setInterval(this._sendKeepalive, 5000)

        this._dg.on(
          LiveTranscriptionEvents.Transcript,
          (data: LiveTranscriptionEvent) => {
            // jsLogger.log('DG RESULTS:', data)
            const t = get(data.channel.alternatives, [0, 'transcript'])
            jsLogger.log('DG: on Transcript', { transcript: t, data })
            // const transcript = data.channel.alternatives[0].transcript
            this._onSocketMessage(data)
          }
        )

        this._dg.on(LiveTranscriptionEvents.UtteranceEnd, () => {
          this._onUtteranceEnd()
        })

        this._dg.on(LiveTranscriptionEvents.SpeechStarted, data => {
          jsLogger.log('Speech started event', data)
          this._isSpeaking = true
        })

        this._dg.on('warning', e => jsLogger.warn('DG WARNING:', e))

        this._dg.on('Metadata', e => jsLogger.log('DG METADATA:', e))

        this._dg.on('close', e => {
          jsLogger.log('DG CLOSE:', e)
          this._dgConnected = false
          clearInterval(this._keepaliveTimeer)
        })
      })
    } catch (e) {
      jsLogger.warn('DG connection error', e)
    }

    // jsLogger.log('RAUDIO: connecting to Deepgram')
    // this._dg = new LiveTranscription(jsLogger.log, dgKey)
    // // deepgramSocketRef.current = dg
    // this._dg.addListener(LiveTranscriptionEvents.Open, () => {
    //   jsLogger.log('ON SOCKET OPEN')
    //   jsLogger.log('RAUDIO: transcriber is connected')
    // })
    // this._dg.addListener(LiveTranscriptionEvents.DeepgramConnected, () => {
    //   // jsLogger.log('ON SOCKET OPEN')
    //   jsLogger.log('RAUDIO: Deepgram is connected')
    // })
    // this._dg.addListener(LiveTranscriptionEvents.Close, () => {
    //   jsLogger.log('ON SOCKET CLOSE')
    //   jsLogger.log('RAUDIO: transcriber is DISCONNECTED')
    // })
    // this._dg.addListener(LiveTranscriptionEvents.Error, e => {
    //   jsLogger.log('ON SOCKET ERROR', e)
    //   jsLogger.log('ERROR | RAUDIO: transcriber connection error', {
    //     error: e
    //   })
    // })
    // this._dg.addListener(
    //   LiveTranscriptionEvents.TranscriptReceived,
    //   this._onSocketMessage
    // )
    // this._dg.addListener(
    //   LiveTranscriptionEvents.UtteranceEnd,
    //   this._onUtteranceEnd
    // )
  }

  private _onUtteranceEnd = () => {
    jsLogger.log('RAUDIO: UTTERANCE END received from Deepgram')
    if (this._isSpeaking) {
      if (this._speach !== '') {
        this._onFinalDetected(this._speach)
        this._isSpeaking = false
        this._speach = ''
        this._onSpeach('')
      }
    }
    // if (this._speach[0] !== '' || this._speach[1] !== '') {
    //   const s = [...this._speach] as [string, string]
    //   this._resetSpeech()
    //   this._onFinalDetected && this._onFinalDetected(s)
    //   // this._dg.stop()
    // }
  }

  private _onSocketMessage = (received: LiveTranscriptionEvent) => {
    // jsLogger.log('socket message', message)
    // jsLogger.log('socket message:', received)
    const t = get(received, ['channel', 'alternatives', 0, 'transcript'])
    const isFinal = received.is_final
    const speachFinal = received.speech_final
    // const transcripts = [[], []]
    // const words = get(received, ['channel', 'alternatives', 0, 'words'])
    // forEach(words, w => {
    //   if (w.speaker === 0) {
    //     transcripts[0].push(w.punctuated_word)
    //   } else if (w.speaker === 1) {
    //     transcripts[1].push(w.punctuated_word)
    //   }
    // })

    // const speaker0 = transcripts[0].join(' ').trim()
    // const speaker1 = transcripts[1].join(' ').trim()

    // jsLogger.log('%cspeaker 0', 'color:yellow', speaker0)
    // jsLogger.log('%cspeaker 1', 'color:yellow', speaker1)

    if (t !== '') {
      jsLogger.log('transcript received from Deepgram', {
        // message: t,
        t,
        isFinal,
        speachFinal
      })
      this._onStartTalking && this._onStartTalking()
    }

    if (isFinal && t !== '') {
      this._speach = this._speach + ' ' + t
      this._onSpeach(this._speach)
    } else if (t !== '') {
      this._onSpeach(this._speach + ' ' + t)
    }

    if (isFinal && speachFinal && this._speach !== '') {
      this._onFinalDetected(this._speach)
      this._speach = ''
      this._isSpeaking = false
      this._onSpeach('')
    }

    if (isFinal) {
      // this._speach = [speaker0, speaker1]
    }
    // if (isFinal && t && t !== '') {
    // if (speaker0 !== '') {
    //   this._speach[0] = this._speach[0] + ' ' + speaker0
    // }
    // if (speaker1 !== '') {
    //   this._speach[1] = this._speach[1] + ' ' + speaker1
    // }

    // } else {
    //   this._onSpeach &&
    //     this._onSpeach([
    //       this._speach[0] + ' ' + speaker0,
    //       this._speach[1] + ' ' + speaker1
    //     ])
    // }

    // if (isFinal && speachFinal && (speaker0 !== '' || speaker1 !== '')) {
    //   // this._speach = [speaker0, speaker1]
    //   // this._onSpeach && this._onSpeach(this._speach)
    //   // deepgramSocketRef.current?.send(JSON.stringify({ type: 'CloseStream' }))
    //   jsLogger.log('FINAL PHRASE IS DETECTED')
    //   this._resetSpeech()
    //   this._onFinalDetected && this._onFinalDetected([speaker0, speaker1])
    //   // this._dg.stop()
    //   // handleStopRecording()
    // }
    // this._onSpeach && this._onSpeach(this._speach)
  }

  stop = () => {
    jsLogger.log('Raudio STOP')
    if (this._dg && this._dgConnected) {
      this._dg.finish()
      clearInterval(this._keepaliveTimeer)
    }
    this._source && this._source.disconnect()
    this._recorderNode && this._recorderNode.disconnect()
    this._audioContext &&
      this._audioContext.state !== 'closed' &&
      this._audioContext.close()
  }

  start = async () => {
    jsLogger.debug('Deepgram start')
    this._speach = ''
    this._audioContext = new AudioContext({ sampleRate: PREFERRED_SAMPLE_RATE })
    try {
      this._source = this._audioContext.createMediaStreamSource(this._stream)
    } catch (e) {
      this._audioContext = new AudioContext() // some browsers can’t return arbitrary audio sample rate, such as firefox
      this._source = this._audioContext.createMediaStreamSource(this._stream)
    }
    const packetSamples =
      (this._audioContext.sampleRate * PACKET_DURATION) / MILLISECOND
    let port: any
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    if (isSafari) {
      const recorder = this._audioContext.createScriptProcessor(BUFFER_SIZE)
      this._source.connect(recorder)
      port = {}
      let queueLength = 0
      const start = Date.now()
      let num = 0
      const queue = new Int16Array(packetSamples)
      recorder.onaudioprocess = function (e) {
        if (port.onmessage) {
          const left = e.inputBuffer.getChannelData(0)
          const right = e.inputBuffer.getChannelData(1) || left
          if (left && right) {
            for (let i = 0; i < left.length; i++) {
              queue[queueLength++] =
                (left[i] + right[i]) * STEREO_FLOAT_TO_MONO_INT16
              if (queueLength === queue.length) {
                queueLength = 0
                port.onmessage({
                  data: {
                    data: queue,
                    log: [Date.now(), Date.now() - start, ++num]
                  }
                })
              }
            }
          }
        }
      }
      recorder.connect(this._audioContext.destination)
    } else {
      const blob = new Blob(
        [
          `
registerProcessor("pcm-processor", class extends AudioWorkletProcessor {
  constructor(options) {
    super();
    this.start=Date.now();
	this.num=0;
	this.queue=new Int16Array(${packetSamples});
	this.queueLength=0;
  }
  process(input) {
    const left = input[0][0];
    const right = input[0][1]||left;
	if(left && right){
		for(let i=0;i<left.length;i++){
			this.queue[this.queueLength++]=(left[i]+right[i])*${STEREO_FLOAT_TO_MONO_INT16};
			if(this.queueLength===this.queue.length){
					this.queueLength=0;
					this.port.postMessage({data:this.queue,log:[Date.now(),Date.now()-this.start,++this.num]});
			}
		}
	}
    return true;
  }
});`
        ],
        { type: 'application/javascript; charset=utf-8' }
      )
      const url = URL.createObjectURL(blob)
      await this._audioContext.audioWorklet.addModule(url)
      this._recorderNode = new AudioWorkletNode(
        this._audioContext,
        'pcm-processor'
      )
      this._recorderNode.connect(this._audioContext.destination)
      port = this._recorderNode.port
      this._source.connect(this._recorderNode)
    }

    port.onmessage = ({ data }: { data: any }) => {
      const blob = new Blob([data.data.buffer])
      if (this._dg && this._dgConnected) {
        this._dg.send(blob)
      } else {
        jsLogger.warn('ignore the blob, DG connected', this._dgConnected)
      }
    }

    this._transcriberConnect(this._audioContext.sampleRate)
  }
}

export default RAudio
