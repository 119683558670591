import {
  useRef,
  useEffect,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState
} from 'react'

import {
  Configuration,
  NewSessionData,
  StreamingAvatarApi
} from '@heygen/streaming-avatar'

import RVideo from 'shared/components/recorder/Rvideo'
import get from 'lodash/get'
// import VoiceDetector from 'shared/components/VoiceDetector'
import BackgroundRemover from 'shared/components/BackgroundRemover'
import jsLogger from 'js-logger'

// const apiKey = process.env.REACT_APP_HEYGEN_KEY
const SERVER_URL = 'https://api.heygen.com'

export interface IHeyGenConnect {
  say: (text: string) => void
  stop: () => Promise<void>
  cancel: () => void
  play: () => void
}

type Props = {
  onAvatarPlayingFinished: (latency: number) => void
  thereWasAnError?: string
  setThereWasAnError?: (v: string) => void
  onSessionStarted: () => void
  isRecording: boolean
  permissionsGranted: boolean
  handleChunk?: (videoBlob: Blob, mimeType: string, role: 'avatar') => void
  setDuration: (v: number) => void
  handleVideoClick: () => void
  heygenKey: string
}

const HeyGenConnect: ForwardRefRenderFunction<IHeyGenConnect, Props> = (
  {
    onAvatarPlayingFinished,
    thereWasAnError,
    setThereWasAnError,
    onSessionStarted,
    isRecording,
    permissionsGranted,
    handleChunk,
    setDuration,
    handleVideoClick,
    heygenKey
  },
  ref
) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const videoMutedRef = useRef<HTMLVideoElement>(null)
  const sessionIdRef = useRef()
  // const peerConnectionRef = useRef<RTCPeerConnection>()
  const videoRecorderRef = useRef<RVideo>()
  const streamHasBeenSet = useRef<boolean>(false)
  // const sdpRef = useRef<any>(null)
  // const jobRef = useRef<() => void | null>(null)
  const jobStartTime = useRef<number>(0)
  const jobRunTime = useRef<number>(0)
  // const [voiceDetectorEnabled, setVoiceDetectorEnabled] = useState(false)

  // const heygenStartRespondedRef = useRef(false)
  const messagePartsRef = useRef<string[]>([])
  const permissionsGrantedRef = useRef<boolean>(false)

  const [stream, setStream] = useState<MediaStream>()
  const [initialized, setInitialized] = useState(false) // Track initialization
  const [data, setData] = useState<NewSessionData>()

  const avatar = useRef<StreamingAvatarApi | null>(null)

  useImperativeHandle(ref, () => ({
    say: (t: string) => {
      jsLogger.log('say', t)
      startTalk(t)
    },
    cancel: cancelTalking,
    stop: stopSession,
    play
  }))

  // useEffect(() => {
  //   connect()
  //   return () => {
  //     stopSession()
  //     const pc = peerConnectionRef.current
  //     if (pc) {
  //       pc.close()
  //     }
  //   }
  // }, [])

  const play = () => {
    if (!streamHasBeenSet.current) {
      playVideo()
    }
  }

  useEffect(() => {
    if (permissionsGranted && stream) {
      permissionsGrantedRef.current = true
      // setTimeout(playVideo, 500)
      playVideo()
    }
  }, [permissionsGranted, stream])

  useEffect(() => {
    const init = async () => {
      jsLogger.log('HEYGEN: Initializing with Access Token:', heygenKey)
      avatar.current = new StreamingAvatarApi(
        new Configuration({ accessToken: heygenKey, jitterBuffer: 200 })
      )
      avatar.current.addEventHandler('avatar_stop_talking', onAvatarPlayingFinished)
      setInitialized(true) // Set initialized to true
      startSession()
    }
    init()

    return () => {
      endSession()
    }
  }, [])

  async function endSession () {
    if (!initialized || !avatar.current) {
      return
    }
    await avatar.current.stopAvatar(
      { stopSessionRequest: { sessionId: data?.sessionId } },
      (s: string) => jsLogger.log('stop result', { s })
    )
    setStream(undefined)
    if (videoRecorderRef.current) {
      videoRecorderRef.current.stop()
    }
  }

  const cancelTalking = () => {}
  // const onVideoRecordingStopped = async () => {
  //   if (onRecordingComplete && videoRecorderRef.current) {
  //     jsLogger.log(
  //       'HEYGEN: onVideoRecordingStopped, blobs amount',
  //       videoRecorderRef.current.blobs.length
  //     )
  //     jsLogger.log('recorder vide type', videoRecorderRef.current.mimeType)

  //     const recordedBlobs = videoRecorderRef.current.blobs
  //     const videoBlob =
  //       recordedBlobs.length === 1
  //         ? recordedBlobs[0]
  //         : new Blob(recordedBlobs, {
  //           type: videoRecorderRef.current.mimeType
  //         })
  //     onRecordingComplete(
  //       videoBlob,
  //       videoRecorderRef.current.mimeType,
  //       videoRecorderRef.current.duration
  //     )
  //   }
  // }

  const avatarCompleted = () => {
    jsLogger.info('avatar finished talking')
    if (messagePartsRef.current.length > 0) {
      jsLogger.log('some parts of the phrase are left', {
        parts: messagePartsRef.current
      })
      startTalk(messagePartsRef.current[0])
    } else {
      onAvatarPlayingFinished(jobRunTime.current - jobStartTime.current)
    }
  }

  const startTalk = async (msg: string) => {
    const response = await avatar.current
      .speak({ taskRequest: { text: msg, sessionId: data?.sessionId } })
      .catch(e => {
        jsLogger.error(e.message)
      })
    jsLogger.log('hg start talk response', response)
  }

  const _startTalk = async (msg: string) => {
    // jsLogger.log('startTalk, d-id key', process.env.REACT_APP_D_ID_KEY)
    const sessionId = sessionIdRef.current
    if (sessionId) {
      jsLogger.debug('start talk', { text: msg })
      const ar =
        msg.length > 10000
          ? msg.replace(/([.?!])\s*(?=[A-Z])/g, '$1|').split('|')
          : [msg]
      jsLogger.log('PHRASES ARRAY', ar)
      if (messagePartsRef.current.length > 0) {
        msg = messagePartsRef.current[0]
        messagePartsRef.current.shift()
      } else if (ar.length > 1) {
        jsLogger.log('the message is split on several phrases', { parts: ar })
        msg = ar[0]
        ar.shift()
        messagePartsRef.current = ar
      }
      // logEvent('HeyGen: call streaming.talk', { text: msg })
      const response = await fetch(`${SERVER_URL}/v1/streaming.task`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': heygenKey
        },
        body: JSON.stringify({
          session_id: sessionId,
          text: msg,
          task_type: 'repeat'
        })
      })
      if (!response.ok && response.status !== 400) {
        jsLogger.error('Server error')
        // updateStatus(
        //   statusElement,
        //   'Server Error. Please ask the staff if the service has been turned on'
        // )
        jsLogger.error('ERROR: HeyGen: streaming.talk - FAIL', {
          code: response.status,
          status: response.statusText
        })
        throw new Error('Server error')
      } else {
        const data = await response.json()
        jsLogger.log('start talk response', data)
        jsLogger.log(data.code, data.message)
        jsLogger.log('HeyGen: streaming.talk - OK', data)
        if (data.code === 10002 || data.code === 10001) {
          jsLogger.warn(
            'WARNING: HeyGen: streaming.talk - data.code is 10002 will retry in 500ms'
          )
          setTimeout(() => startTalk(msg), 500)
        } else {
          const duration = get(data, 'data.duration_ms')
          jsLogger.log('avatar speech duration should be', duration)
          if (duration) {
            setTimeout(avatarCompleted, Number(duration) + 1000)

            // jobStartTime.current = Date.now()
            // jsLogger.log('jobStartTime', jobStartTime.current)
            // jobRef.current = () => {
            //   setTimeout(() => {
            //     avatarCompleted()
            //   }, Number(duration - 500))
            // }
            // setVoiceDetectorEnabled(true)
          }
        }
      }
    } else {
      jsLogger.error('cannot run talk task, session is not initialized')
    }
  }

  const playVideo = () => {
    jsLogger.log(
      '%cplayVideo, permisssions granted',
      'color: gold;',
      permissionsGrantedRef.current
    )
    // const v = permissionsGranted ? videoRef.current : videoMutedRef.current
    if (!permissionsGrantedRef.current) {
      jsLogger.log('playVideo: permissions are not granted, exit playVideo')
      return null
    }
    const v = videoRef.current
    // const v = videoRef.current
    if (stream && v) {
      if (!videoRecorderRef.current) {
        videoRecorderRef.current = new RVideo(
          stream,
          (v: number) => setDuration(v),
          onNewChunk
        )
      }
      jsLogger.log('pause current video')
      v.pause()

      v.srcObject = stream
      if (!permissionsGrantedRef.current) {
        v.autoplay = false
        v.loop = false
      }
      // v.muted = false
      streamHasBeenSet.current = true
      // v.muted = false
      const playPromise = v.play()
      if (v.paused && playPromise) {
        v.play()
          .then(() => {
            jsLogger.log('--> Video Play success')
          })
          .catch(e => jsLogger.log('--> Video Play error', e.message))
      } else {
        jsLogger.log('Video is not paused')
      }
      if (permissionsGrantedRef.current) {
        onSessionStarted()
      }
      // startSession(sessionIdRef.current, sdpRef.current)
    } else {
      jsLogger.warn('playVideo stream or videoref is not ready')
    }
  }

  const setVideoElement = async (stream: MediaStream) => {
    jsLogger.log('setVideoElement', stream.id)
    jsLogger.log(
      'setVideoElement: permissions granted',
      permissionsGrantedRef.current
    )
    playVideo()
    // if (permissionsGranted) {
    //   // playVideo()
    // }
  }

  // const newSession = async (
  //   quality: string
  //   // avatarName?: string,
  //   // voiceName?: string
  // ) => {
  //   jsLogger.debug('HeyGen: streaming.new call start', heygenKey)
  //   const response = await fetch(`${SERVER_URL}/v1/streaming.new`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'x-api-key': heygenKey
  //     },
  //     body: JSON.stringify({
  //       quality
  //       // avatar_name: avatarName,
  //       // voice: voiceName
  //     })
  //   })
  //   jsLogger.debug('HeyGen: streaming.new call end', {
  //     status: response.status,
  //     statusText: response.statusText
  //   })
  //   if (response.ok) {
  //     const data = await response.json()
  //     jsLogger.log('HeyGen: streaming.new successful', {
  //       sessionId: data.data.session_id
  //     })
  //     return data.data
  //   } else {
  //     jsLogger.error('ERROR: HeyGen: streaming.new fail', {
  //       code: response.status,
  //       status: response.statusText
  //     })
  //     setThereWasAnError(
  //       'Server Error. Please ask the staff if the service has been turned on'
  //     )
  //   }
  // }

  // const handleICE = async (sessionId: string, candidate: object) => {
  //   jsLogger.log('HeyGen: streaming.ice call start')
  //   const response = await fetch(`${SERVER_URL}/v1/streaming.ice`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'x-api-key': heygenKey
  //     },
  //     body: JSON.stringify({ session_id: sessionId, candidate })
  //   })
  //   jsLogger.log('HeyGen: streaming.ice call end', {
  //     sessionId,
  //     status: response.status,
  //     statusText: response.statusText
  //   })
  //   if (response.status === 500) {
  //     jsLogger.error('ERROR: HeyGen streaming.ice failed', {
  //       sessionId,
  //       code: response.status,
  //       status: response.statusText
  //     })
  //     throw new Error('Server error')
  //   } else {
  //     const data = await response.json()
  //     jsLogger.log('HeyGen: streaming.ice call - ok')
  //     return data
  //   }
  // }

  async function startSession () {
    // setIsLoadingSession(true);
    // await updateToken();
    if (!avatar.current) {
      jsLogger.error('Avatar API is not initialized')
      return
    }
    try {
      const res = await avatar.current.createStartAvatar(
        {
          newSessionRequest: {
            quality: 'high'
            // avatarName: avatarId,
            // voice: { voiceId: voiceId }
          }
        },
        (s: string) => jsLogger.log('createStartAvatar res', { s })
      )
      setData(res)
      setStream(avatar.current.mediaStream)
      setVideoElement(avatar.current.mediaStream)
    } catch (error) {
      console.error('Error starting avatar session:', error)
      jsLogger.error('There was an error starting the session.')
    }
    // setIsLoadingSession(false)
  }

  // const startSession = async (
  //   sessionId: string,
  //   // eslint-disable-next-line no-undef
  //   sdp: RTCSessionDescriptionInit
  // ) => {
  //   jsLogger.log('start session', sessionId, sdp)
  //   const params = {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'x-api-key': heygenKey
  //     },
  //     keepalive: true,
  //     body: JSON.stringify({ session_id: sessionId, sdp })
  //   }
  //   jsLogger.log('call streaming.start with params')

  //   setTimeout(() => {
  //     if (!heygenStartRespondedRef.current) {
  //       jsLogger.warn('HeyGen: streaming.start is pending more than 10 seconds')
  //     }
  //   }, 10000)
  //   jsLogger.debug('HeyGen: streaming.start call start', { sessionId })
  //   const response = await fetch(`${SERVER_URL}/v1/streaming.start`, params)
  //   jsLogger.debug('HeyGen: streaming.start call end', {
  //     sessionId,
  //     status: response.status,
  //     statusText: response.statusText
  //   })
  //   heygenStartRespondedRef.current = true
  //   if (!response.ok && response.status !== 400) {
  //     jsLogger.error('ERROR: HeyGen: streaming.start - FAIL', {
  //       code: response.status,
  //       status: response.statusText
  //     })
  //     setThereWasAnError(
  //       'Server Error. Please ask the staff if the service has been turned on'
  //     )
  //     throw new Error(
  //       'HEYGEN Server error: Please ask the staff if the service has been turned on'
  //     )
  //   } else if (response.status === 400) {
  //     const data = await response.json()
  //     jsLogger.error('ERROR: HeyGen: streaming.start - FAIL', data)
  //   } else if (response.ok) {
  //     const data = await response.json()
  //     jsLogger.log('HeyGen: streaming.start - OK', data)
  //     return data.data
  //   }
  // }

  // const startAndDisplaySession = async sessionInfo => {
  //   if (!sessionInfo) {
  //     jsLogger.warn('HeyGen: cannnot start session, no session info')
  //     setThereWasAnError('Please create a connection first')
  //     return
  //   }

  //   jsLogger.log('%cStarting session... please wait', 'color: green')

  //   // Create and set local SDP description
  //   jsLogger.log('HeyGen: set local description - start')
  //   const localDescription = await peerConnectionRef.current.createAnswer()
  //   await peerConnectionRef.current.setLocalDescription(localDescription)
  //   jsLogger.log('HeyGen: set local description - end')

  //   sdpRef.current = localDescription
  //   // Start session
  //   jsLogger.log('%cSession initialized', 'color: green')
  //   // if (permissionsGranted) {
  //   await startSession(sessionInfo.session_id, localDescription)
  //   // } else {
  //   //   // sdpRef.current = localDescription
  //   // }
  // }

  // const connect = async () => {
  //   const previewsSessionId = localStorage.getItem('heygenSessionId')
  //   if (previewsSessionId) {
  //     jsLogger.log('previews session id is found', { previewsSessionId })
  //     await _stopSession(previewsSessionId)
  //   }
  //   // call the new interface to get the server's offer SDP and ICE server to create a new RTCPeerConnection
  //   // const sessionInfo = await newSession('high')
  //   const sessionInfo = await newSession('low')
  //   const { sdp: serverSdp, ice_servers2: iceServers } = sessionInfo

  //   sessionIdRef.current = sessionInfo.session_id
  //   localStorage.setItem('heygenSessionId', sessionInfo.session_id)

  //   // Create a new RTCPeerConnection
  //   peerConnectionRef.current = new RTCPeerConnection({ iceServers })
  //   // const formattedIceServers = iceServers.map(server => ({ urls: server }))
  //   // peerConnectionRef.current.setConfiguration({
  //   //   iceServers: formattedIceServers
  //   // })

  //   // When ICE candidate is available, send to the server
  //   peerConnectionRef.current.onicecandidate = ({ candidate }) => {
  //     // jsLogger.log('Received ICE candidate:', get(candidate, 'address'))
  //     if (candidate) {
  //       // logEvent('HeyGen received ice candidate')
  //       handleICE(sessionInfo.session_id, candidate.toJSON())
  //     }
  //   }

  //   // When ICE connection state changes, display the new state
  //   peerConnectionRef.current.oniceconnectionstatechange = () => {
  //     jsLogger.log('ICE connection state changed to:', {
  //       state: peerConnectionRef.current.iceConnectionState
  //     })
  //   }

  //   peerConnectionRef.current.onconnectionstatechange = (event: Event) => {
  //     jsLogger.log('HeyGen: connection state changed:', {
  //       state: peerConnectionRef.current.connectionState,
  //       eventType: event.type
  //     })
  //   }

  //   peerConnectionRef.current.ondatachannel = event => {
  //     jsLogger.log('ondatachannel-----, event', event)
  //   }

  //   // When audio and video streams are received, display them in the video element
  //   peerConnectionRef.current.ontrack = event => {
  //     // logEvent('Received the track', { kind: event.track.kind })
  //     if (event.track.kind === 'audio' || event.track.kind === 'video') {
  //       // event.track.enabled = false
  //       if (!streamRef.current) {
  //         jsLogger.log('HeyGen: media track received', {
  //           trackKind: event.track.kind
  //         })
  //         setVideoElement(event.streams[0])
  //       }
  //     }
  //   }

  //   // Set server's SDP as remote description
  //   const remoteDescription = new RTCSessionDescription(serverSdp)
  //   try {
  //     await peerConnectionRef.current.setRemoteDescription(remoteDescription)
  //   } catch (e) {
  //     jsLogger.error('setRemoteDescription error', get(e, 'message'))
  //   }

  //   jsLogger.log('Session creation completed')
  //   await startAndDisplaySession(sessionInfo)

  //   // setTimeout(() => startTalk(initialPhrase), 500)
  // }

  // const _stopSession = async (sessionId: string) => {
  //   const response = await fetch(`${SERVER_URL}/v1/streaming.stop`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'x-api-key': heygenKey
  //     },
  //     body: JSON.stringify({ session_id: sessionId })
  //   })
  //   if (response.status === 500) {
  //     jsLogger.error(
  //       'HeyGen stopSession call error:',
  //       response.status,
  //       response.statusText
  //     )
  //     // updateStatus(statusElement, "Server Error. Please ask the staff for help");
  //     throw new Error('Server error')
  //   } else {
  //     const data = await response.json()
  //     return data.data
  //   }
  // }

  const stopSession = async () => {
    // const sessionId = sessionIdRef.current
    // if (videoRecorderRef.current) {
    //   videoRecorderRef.current.stop()
    // }
    // if (sessionId) {
    //   _stopSession(sessionId)
    // }
  }

  // const onVoiceDetected = () => {
  //   const job = jobRef.current
  //   if (job) {
  //     jsLogger.log('HeyGen: avatar voice detected')
  //     jobRunTime.current = Date.now()
  //     job()
  //   }
  //   setVoiceDetectorEnabled(false)
  // }

  const onNewChunk = (blob: Blob) => {
    const mimeType = videoRecorderRef.current.mimeType
    handleChunk && handleChunk(blob, mimeType, 'avatar')
  }

  return (
    <div
      className='h-full w-full flex flex-col justify-between flex-1 max-w-2xl relative'
      onClick={handleVideoClick}
    >
      {permissionsGranted ? (
        <>
          <video
            key={'heygen_video'}
            id='heygen_video'
            ref={videoRef}
            className='w-full h-full object-cover absolute left-0 top-0 right-0 bottom-0 bg-blackAlpha-800'
            playsInline
            controls={false}
            autoPlay={false}
            muted={false}
            onAbort={() => jsLogger.log('Video: onAbort')}
            onError={e => jsLogger.log('Video: onError', get(e, 'message'))}
          />
          <BackgroundRemover key={'heygenVideo'} videoRef={videoRef} />
        </>
      ) : (
        <>
          <video
            key={'muted_video'}
            id='muted_video'
            src='avatar_initial.mp4'
            poster='https://firebasestorage.googleapis.com/v0/b/tenantflow-ace23.appspot.com/o/avatar_initial_bg.png?alt=media&token=3653cda3-365d-4ddc-9e4b-2160bd949f7b'
            ref={videoMutedRef}
            className='w-full h-full object-cover absolute left-0 top-0 right-0 bottom-0'
            playsInline
            autoPlay
            loop
            muted
            onAbort={() => jsLogger.log('Muted Video: onAbort')}
            onError={e =>
              jsLogger.log('Muted Video: onError ' + get(e, 'message'))
            }
          />
          <BackgroundRemover key={'mutedVideo'} videoRef={videoMutedRef} />
        </>
      )}

      {/* {!permissionsGranted && (
        <div className='absolute top-0 left-0 bottom-0 right-0 bg-gray-700 w-full h-full' />
      )} */}
      {isRecording && (
        <div className='absolute top-0 left-0 bottom-0 right-0 bg-black/60 w-full h-full' />
      )}
      {thereWasAnError && (
        <div className='absolute bottom-0 left-0 bg-white'>
          <p className='text-red'>{thereWasAnError}</p>
        </div>
      )}
      {
        // voiceDetectorEnabled && (
        // <VoiceDetector
        //   streamRef={streamRef}
        //   onVoiceDetected={onVoiceDetected}
        // />
        // )
      }
    </div>
  )
}

export default forwardRef(HeyGenConnect)
