import {
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  ForwardRefRenderFunction,
  forwardRef,
  Ref,
  RefObject,
  MutableRefObject
} from 'react'
import get from 'lodash/get'
import set from 'lodash/set'
import jsLogger from 'js-logger'

import RAudio from 'shared/components/recorder/Raudio'
import RVideo from 'shared/components/recorder/Rvideo'
import { ControlT } from 'shared/types/facesign'
import SilenceDetector from 'shared/components/SilenceDetector'
import RecorderControls from 'shared/components/recorder/RecorderControls'

export interface IRecorder {
  stop: () => void
  start: () => void
  cancel: () => void
  recordingStop: () => void
  isRecording: () => boolean
  cameraChange: (v: string | null) => void
}

const CONSTRAINTS = {
  audio: {
    echoCancellation: true,
    noiseSuppression: false,
    autoGainControl: false,
    mozAutoGainControl: false,
    mozNoiseSuppression: false
  },
  video: {
    facingMode: { ideal: 'user' },
    frameRate: 12
    // aspectRatio: 844 / 390,
    // width: { ideal: 390 },
    // height: { ideal: 844 }
  }
}

type Props = {
  interactionId: string
  onCameraOn?: () => void
  onPhrase: (speech: string, dgLatency: number) => void
  handleChunk?: (videoBlob: Blob, mimeType: string, role: 'user') => void
  recordingStatusChanged?: (isRecording: boolean) => void
  onSpeech?: (v: string) => void
  cameraVideoRef?: RefObject<HTMLVideoElement>
  isActive: boolean
  setThereWasAnError?: (v: string) => void
  permissionsError?: string | null
  controls: ControlT
  streamRef: MutableRefObject<MediaStream | null>
  setControls: (c: ControlT) => void
  dgKey: string
  setDevices: (v: MediaDeviceInfo[]) => void
  setSelectedDeviceId: (v: string | null) => void
  onStartTalking?: () => void
}

const VideoRecorder: ForwardRefRenderFunction<IRecorder, Props> = (
  {
    interactionId,
    handleChunk,
    onCameraOn,
    // recordingStatusChanged,
    onSpeech,
    cameraVideoRef,
    isActive,
    onPhrase,
    setThereWasAnError,
    permissionsError,
    controls,
    streamRef,
    setControls,
    setDevices,
    setSelectedDeviceId,
    dgKey,
    onStartTalking = () => null
  },
  ref: Ref<unknown>
) => {
  const isRecordingRef = useRef(false)
  const [error, setError] = useState<string>()
  const [isInlineRecordingSupported, setIsInlineRecordingSupported] = useState<
    boolean | null
  >(null)
  const [initPass, setInitPass] = useState<boolean>(false)

  const videoRecorderRef = useRef<RVideo>()
  const audioRecorderRef = useRef<RAudio>()
  const silenceDetectedAt = useRef<number>()

  useImperativeHandle(ref, () => ({
    stop: () => {
      stop()
    },
    start: () => {
      start()
    },
    cancel: () => {
      cancel()
    },
    recordingStop: () => {
      recordingStop()
    },
    isRecording: () => {
      return isRecordingRef.current
    },
    cameraChange: (v: string | null) => {
      onCameraChange(v)
    }
  }))

  useEffect(() => {
    init()
    return finilize
  }, [])

  // const setIsRecording = (v: boolean) => {
  //   isRecordingRef.current = v
  //   silenceDetectedAt.current = undefined
  //   if (recordingStatusChanged) {
  //     recordingStatusChanged(v)
  //   }
  // }

  useEffect(() => {
    if (!initPass && controls.camera && controls.mic) {
      setInitPass(true)
      turnOnCamera()
    }
  }, [controls, initPass])

  const setSpeech = (s: string) => {
    onSpeech && onSpeech(s)
  }

  const init = () => {
    jsLogger.log('RECORDER: init')

    const _isInlineRecordingSupported =
      !!window.MediaRecorder && !!navigator.mediaDevices
    jsLogger.log('RECORDER: init', { _isInlineRecordingSupported })
    if (!_isInlineRecordingSupported) {
      jsLogger.warn('WARNINIG | RECORDER: inline recording is not supported')
    }
    setIsInlineRecordingSupported(_isInlineRecordingSupported)
  }

  const recordingStop = () => {
    jsLogger.log('recording stop')
    videoRecorderRef.current?.stop()
  }

  const initAudioRecorder = () => {
    jsLogger.log('---> initAudioRecorder')
    audioRecorderRef.current = new RAudio(
      streamRef.current,
      onSpeach,
      onFinalDetected,
      interactionId,
      dgKey,
      onStartTalking
    )
    // audioRecorderRef.current.start()
  }

  const finilize = () => {
    jsLogger.log('finilize')
    audioRecorderRef.current?.stop()
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop())
    }
  }

  const turnOnCamera = async () => {
    jsLogger.log('Recorder | turnOnCamera: start')
    const mediaDevices = await navigator.mediaDevices.enumerateDevices()
    jsLogger.log('mediaDevices', mediaDevices)
    const videoDevices = mediaDevices.filter(x => x.kind === 'videoinput')
    const currentDeviceId = get(videoDevices, [0, 'deviceId'])
    jsLogger.log('Recorder | the first video device is', { currentDeviceId })

    const constraints = navigator.mediaDevices.getSupportedConstraints()
    jsLogger.log('supported constraints', constraints)
    try {
      const constraints = {
        audio: {
          echoCancellation: true
          // noiseSuppression: true,
          // autoGainControl: true,
          // mozAutoGainControl: true,
          // mozNoiseSuppression: true
        },
        video: {
          // deviceId: {
          //   exact: currentDeviceId
          // }
          frameRate: 12
          // aspectRatio: 844 / 390,
          // width: { ideal: 390 },
          // height: { ideal: 844 }
        }
      }

      setSelectedDeviceId(currentDeviceId)
      setDevices(videoDevices)
      if (currentDeviceId) {
        set(CONSTRAINTS, 'video.deviceId', currentDeviceId)
      }

      jsLogger.log('Recorder | navigator.mediaDevices.getUserMedia call', {
        constraints
      })

      const stream = await navigator.mediaDevices.getUserMedia(constraints)
      jsLogger.log(
        'Recorder | navigator.mediaDevices.getUserMedia done, stream created',
        { streamId: stream.id }
      )
      streamRef.current = stream
      initAudioRecorder()
      if (cameraVideoRef && cameraVideoRef.current) {
        cameraVideoRef.current.srcObject = stream
        onCameraOn && onCameraOn()
      }
      if (stream) {
        videoRecorderRef.current = new RVideo(
          streamRef.current,
          onVideoRecordingStopped,
          onNewChunk
        )
      }
    } catch (e) {
      jsLogger.error('ERROR: Recorder | turnOnCamera failed', {
        msg: e.message
      })
      setError(get(e, 'message'))
      if (setThereWasAnError) setThereWasAnError(get(e, 'message', ''))
    }
  }

  const onVideoRecordingStopped = async () => {
    // audioRecorderRef.current?.stop()
  }

  const onCameraChange = async (selectedDeviceId: string | null) => {
    console.log('Recorder | camera change', selectedDeviceId)
    streamRef.current.getTracks().forEach(track => {
      track.stop()
    })
    if (selectedDeviceId) {
      set(CONSTRAINTS, 'video.deviceId', selectedDeviceId)
    }
    const stream = await navigator.mediaDevices.getUserMedia(CONSTRAINTS)
    jsLogger.log(
      'Recorder | navigator.mediaDevices.getUserMedia done, stream updated',
      { streamId: stream.id, selectedDeviceId }
    )
    streamRef.current = stream
    initAudioRecorder()
    if (cameraVideoRef && cameraVideoRef.current) {
      cameraVideoRef.current.srcObject = stream
    }
    if (stream) {
      videoRecorderRef.current = new RVideo(
        streamRef.current,
        onVideoRecordingStopped,
        onNewChunk
      )
    }
  }

  const onSpeach = (s: string) => setSpeech(s)

  const onFinalDetected = (currentTranscript: string) => {
    jsLogger.log('End of speech Detected', {
      transcript: currentTranscript
    })
    onPhrase(currentTranscript, 0)
  }

  const start = async () => {
    // jsLogger.log('%cRecorder Start', 'color: orange;')
  }

  const stop = () => {
    // jsLogger.log('%cRecorder Stop', 'color: orange;')
    // setIsRecording(false)
    // videoRecorderRef.current?.stop()
    // audioRecorderRef.current?.stop()
  }

  const cancel = () => {
    // jsLogger.log('%cRecorder Cancel', 'color: orange;')
    // setIsRecording(false)
    // audioRecorderRef.current?.stop()
  }

  const handleMicOrCameraClick = (type: 'mic' | 'camera') => {
    jsLogger.log(`RECORDER: handleMicOrCameraClick: ${type}`, {
      newControls: { ...controls, [type]: !controls[type] }
    })
    setControls({ ...controls, [type]: !controls[type] })
  }

  const onNewChunk = (blob: Blob) => {
    const mimeType = videoRecorderRef.current.mimeType
    handleChunk && handleChunk(blob, mimeType, 'user')
  }

  const renderControls = () => {
    return (
      <RecorderControls
        controls={controls}
        handleMicOrCameraClick={handleMicOrCameraClick}
      />
    )
  }

  const onSilenceDetected = () => {
    // jsLogger.log('onSilenceDetected')
    // onFinalDetected()
    jsLogger.log('RECORDER: silence detected')
    silenceDetectedAt.current = Date.now()
  }

  const renderCameraView = () => {
    if (!isInlineRecordingSupported && isInlineRecordingSupported !== null) {
      return <p>This browser is uncapable of recording video</p>
    }

    if (error) {
      return <p> Error {error}</p>
    }

    return (
      <div key='camera' className='w-full h-full rounded-md'>
        {renderControls()}
        <video
          className={`w-full h-full object-cover rounded-md scale-x-[-1] ${
            !controls?.camera || !controls.mic ? 'opacity-80' : 'opacity-100'
          }`}
          ref={cameraVideoRef}
          autoPlay
          muted
          playsInline
        />
        <SilenceDetector
          streamRef={streamRef}
          isRecording={isActive}
          onSilenceDetected={onSilenceDetected}
          delay={3000}
        />
      </div>
    )
  }

  if (permissionsError) return null

  return (
    <div
      className={
        'absolute top-0 left-0 bottom-0 right-0 w-full h-full rounded-md border' +
        (isActive ? ' border-teal-400' : ' border-transparent')
      }
    >
      {renderCameraView()}
      {!isActive && (
        <div className='absolute top-0 left-0 bottom-0 right-0 w-full h-full rounded-md shadow-lg bg-blackAlpha-400' />
      )}
    </div>
  )
}

export default forwardRef(VideoRecorder)
