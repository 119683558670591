import { FC, useRef } from 'react'

import { ReactComponent as IconLogo } from 'shared/assets/facesign_logo.svg'
import PageContainer from 'shared/components/PageContainer'

type Props = {
  onRetry: () => void
}

const PChromeMobile: FC<Props> = ({ onRetry }) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  return (
    <PageContainer>
      <div className='w-full h-full relative bg-white flex flex-col items-center justify-start'>
        <div className='flex flex-row w-full px-5 justify-center pt-9'>
          <IconLogo />
        </div>
        <span className='w-[345px] font-bold text-[20px] text-center pt-5'>
          Enable Camera and Microphone
        </span>
        <span className='text-center text-[12px] text-gray-600 pt-[10px]'>
          Follow these steps to set your permissions settings to <b>allow.</b>
        </span>
        <div className='w-[190px] h-[300px] mt-6 relative overflow-hidden'>
          <video
            muted
            ref={videoRef}
            className='w-[190px] absolute left-0 top-0'
            src='https://firebasestorage.googleapis.com/v0/b/tenantflow-ace23.appspot.com/o/permissions%2FRPReply_Final1709223912.MP4?alt=media&token=b8a2a3f5-8a46-430e-8906-f0a985b64079'
            poster='https://firebasestorage.googleapis.com/v0/b/tenantflow-ace23.appspot.com/o/permissions%2Fchrome_video_thumbanaik.png?alt=media&token=6951d614-08aa-4191-90f7-040f322e82a5'
            autoPlay
            playsInline
            // controls
            loop
            // onCanPlay={() => {
            //   if (videoRef.current) {
            //     videoRef.current.playbackRate = 2
            //   }
            // }}
          />
        </div>
        <div
          className={'w-full px-5 flex flex-col pt-5 text-gray-600 text-[14px]'}
        >
          <div className='w-full flex flex-row justify-between items-center'>
            <span>1. Open iOS Settings</span>
            <img
              className='w-[44px]'
              src='https://firebasestorage.googleapis.com/v0/b/tenantflow-ace23.appspot.com/o/permissions%2Fchrome_mobile_step1.png?alt=media&token=621212a5-0ae8-4d02-aec2-80f4d50f594f'
            />
          </div>
          <div className='w-full flex flex-row justify-between items-center pt-5'>
            <span>{'2. Settings -> Chrome'}</span>
            <img
              className='w-[121px]'
              src='https://firebasestorage.googleapis.com/v0/b/tenantflow-ace23.appspot.com/o/permissions%2Fchrome_mobile_step2.png?alt=media&token=615a422d-fb18-43fc-83d1-90c601f6836e'
            />
          </div>
          <div className='w-full flex flex-row justify-between items-center pt-5'>
            <div className='flex flex-col'>
              <span>
                3. Enable <b>Microphone</b> and <b>Camera</b>
              </span>
            </div>
            <img
              className='w-[134px]'
              src='https://firebasestorage.googleapis.com/v0/b/tenantflow-ace23.appspot.com/o/permissions%2Fchrome_mobile_step3.png?alt=media&token=16091171-e7ce-4b11-b1f9-019c2277bf79'
            />
          </div>
          <div
            className='w-full px-5 h-[60px] bg-teal-500 mt-6 flex flex-row items-center justify-center text-whiteAlpha-900 hover:cursor-pointer'
            onClick={onRetry}
          >
            <span className='pl-2 text-[20px] font-semibold '>Retry</span>
          </div>
        </div>
      </div>
    </PageContainer>
  )
}

export default PChromeMobile
