import {
  useImperativeHandle,
  ForwardRefRenderFunction,
  forwardRef,
  Ref,
  RefObject,
  MutableRefObject,
  useEffect
} from 'react'
import get from 'lodash/get'
import { ControlT } from 'shared/types/facesign'
import { IRecorder } from 'shared/components/Recorder'
// import SilenceDetector from 'shared/components/SilenceDetector'

type Props = {
  onCameraOn?: () => void
  onPhrase: (speech: string, dgLatency: number) => void
  recordingStatusChanged?: (isRecording: boolean) => void
  onSpeech?: (v: string) => void
  cameraVideoRef?: RefObject<HTMLVideoElement>
  isActive: boolean
  setThereWasAnError?: (v: string) => void
  permissionsError?: string | null
  controls?: ControlT
  streamRef: MutableRefObject<MediaStream | null>
  setControls: ({ camera, mic }: { camera: boolean; mic: boolean }) => void
}

const VideoRecorder: ForwardRefRenderFunction<IRecorder, Props> = (
  { onPhrase },
  ref: Ref<unknown>
) => {
  useImperativeHandle(ref, () => ({
    stop: () => null,
    start: () => null,
    cancel: () => null,
    recordingStop: () => null
  }))

  useEffect(() => {
    const elt = document.getElementById('event_reply_div')
    elt.addEventListener('ev_reply', function (e) {
      const reply = get(e, 'detail')
      console.log('ev_reply received', e)
      console.log('reply', reply)
      if (reply) {
        onPhrase(reply, 0)
      }
    })
  }, [])

  // const onFinalDetected = () => {
  //   // onPhrase(audioRecorderRef.current?.speach || '', 0)
  // }

  return null
}

export default forwardRef(VideoRecorder)
